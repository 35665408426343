/* eslint-disable no-use-before-define */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable no-inner-declarations */
/* eslint-disable max-len */
/* eslint-disable import/order */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import React, { useEffect, useState, useRef } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { custDataNameCookie, features } from '@config';
import { getHost } from '@helper_config';
import { breakPointsUp } from '@helper_theme';
import { getCookies, getVendor } from '@helper_cookies';
import useStyles from './style';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import crypto from 'crypto';
import isMobileApp from '@root/src/helpers/userAgent';

import { addProductsToCartVendor, getGuestCartId as queryGetGuestCartId } from '@modules/product/services/graphql';
import { getCartId, setCartId } from '@helper_cartid';
import PopupInstallAppMobileSkeleton from '@modules/theme/components/custom-install-popup/MobileSkeleton';

const BottomNavigation = dynamic(() => import('@common_bottomnavigation'), {
    ssr: false,
});
const HeaderMobile = dynamic(() => import('@common_headermobile'), { ssr: false });
const HeaderDesktop = dynamic(() => import('@common_headerdesktop'), { ssr: true });
const Message = dynamic(() => import('@common_toast'), { ssr: false });
const Loading = dynamic(() => import('@common_loaders/Backdrop'), {
    ssr: false,
});
// const ScrollToTop = dynamic(() => import('@common_scrolltotop'), {
//     ssr: false,
// });
const PopupInstallAppMobile = dynamic(() => import('@modules/theme/components/custom-install-popup/mobile'), {
    loading: () => <PopupInstallAppMobileSkeleton />,
    ssr: false,
});
const Footer = dynamic(() => import('@common_footer'), { ssr: false });

const Layout = (props) => {
    const footerStyles = useStyles();
    const {
        pageConfig,
        children,
        CustomHeader = false,
        i18n,
        storeConfig = {},
        isLogin,
        headerProps = {},
        t,
        vendorId,
        stickyHeader = false,
    } = props;

    const {
        ogContent = {}, schemaOrg = null, headerDesktop = true, footer = true, fullWidth,
    } = pageConfig;
    const router = useRouter();
    const [state, setState] = useState({
        toastMessage: {
            open: false,
            variant: '',
            text: '',
        },
        backdropLoader: false,
    });
    // const [mainMinimumHeight, setMainMinimumHeight] = useState(0);
    const refFooter = useRef(null);
    const refHeader = useRef(null);

    const handleSetToast = (message) => {
        setState({
            ...state,
            toastMessage: {
                ...state.toastMessage,
                ...message,
            },
        });
    };

    const handleLoader = (status = false) => {
        setState({
            ...state,
            backdropLoader: status,
        });
    };

    const handleCloseMessage = () => {
        setState({
            ...state,
            toastMessage: {
                ...state.toastMessage,
                open: false,
            },
        });
    };

    const ogData = {
        'og:title': pageConfig.title ? pageConfig.title : storeConfig.default_title ? storeConfig.default_title : 'Swift Pwa',
        'og:image': storeConfig.header_logo_src
            ? `${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`
            : `${getHost()}/assets/img/swift-logo.png`,
        'og:image:type': 'image/png',
        'og:url': `${getHost()}${router.asPath}`,
        'og:locale': i18n && i18n.language === 'id' ? 'id_ID' : 'en_US',
        'og:type': 'website',
        ...ogContent,
    };

    if (!ogData['og:description']) {
        ogData['og:description'] = pageConfig.description ? pageConfig.description : storeConfig.default_description || '';
    }

    if (features.facebookMetaId.enabled) {
        ogData['fb:app_id'] = features.facebookMetaId.app_id;
    }
    const [addToCartSimple] = addProductsToCartVendor();
    const [getGuestCartId] = queryGetGuestCartId();
    const canonicalUrl = `${getHost()}${router.asPath.split('?')[0]}`;
    const defaultLang = i18n && i18n.language === 'id' ? 'id' : 'en';

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.toastMessage = handleSetToast;
            window.backdropLoader = handleLoader;
            const custData = Cookies.getJSON(custDataNameCookie);
            const tagManagerArgs = {
                dataLayer: {
                    pageName: pageConfig.title,
                    customerGroup: isLogin === 1 ? 'GENERAL' : 'NOT LOGGED IN',
                },
            };
            if (custData && custData.email) {
                tagManagerArgs.dataLayer.customerId = custData.email;
                tagManagerArgs.dataLayer.eid = crypto.createHash('sha256').update(custData.email).digest('hex');
            }
            TagManager.dataLayer(tagManagerArgs);

            // add to cart from window.AddToCart

            const addToCart = async (sku, qty) => {
                const cartId = getCartId();
                if (!cartId) {
                    try {
                        const guestCart = await getGuestCartId();
                        if (!guestCart) throw guestCart;

                        setCartId(guestCart.data.createEmptyCart);

                        const responseAddSimpleProductToCart = await addToCartSimple({
                            variables: {
                                cartId: guestCart.data.createEmptyCart,
                                sku,
                                qty: parseFloat(qty),
                                vendor_id: parseInt(getVendor() || vendorId, 10),
                                is_pickup: 0,
                            },
                        }).then((res) => {
                            const resErrorMessage = res
                                && res.data
                                && res.data.addProductsToCartVendor
                                && res.data.addProductsToCartVendor.user_errors
                                && res.data.addProductsToCartVendor.user_errors[0]
                                && res.data.addProductsToCartVendor.user_errors[0].message;
                            if (resErrorMessage) {
                                // eslint-disable-next-line no-console
                                console.log(resErrorMessage);
                            } else {
                                // eslint-disable-next-line no-console
                                console.log(t('product:successAddCart'));
                            }
                        });
                        if (!responseAddSimpleProductToCart) throw responseAddSimpleProductToCart;
                    } catch (error) {
                        // console.log(error);
                    }
                } else {
                    try {
                        const responseAddSimpleProductToCart = await addToCartSimple({
                            variables: {
                                cartId,
                                sku,
                                qty: parseFloat(qty),
                                vendor_id: parseInt(getVendor() || vendorId, 10),
                                is_pickup: 0,
                            },
                        }).then((res) => {
                            const resErrorMessage = res
                                && res.data
                                && res.data.addProductsToCartVendor
                                && res.data.addProductsToCartVendor.user_errors
                                && res.data.addProductsToCartVendor.user_errors[0]
                                && res.data.addProductsToCartVendor.user_errors[0].message;
                            if (resErrorMessage) {
                                // eslint-disable-next-line no-console
                                console.log(resErrorMessage);
                            } else {
                                // eslint-disable-next-line no-console
                                console.log(t('product:successAddCart'));
                            }
                        });
                        if (!responseAddSimpleProductToCart) throw responseAddSimpleProductToCart;
                    } catch (error) {
                        // console.log(error);
                    }
                }
            };

            window.AddToCart = addToCart;
        }
        // setMainMinimumHeight(refFooter.current.clientHeight + refHeader.current.clientHeight);
    }, []);

    const desktop = breakPointsUp('sm');

    const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    const styles = {
        marginBottom: pageConfig.bottomNav && !isDesktop ? '50px' : 0,
    };

    if (!headerDesktop) {
        styles.marginTop = 0;
    }
    const [show, setShow] = React.useState(true);
    const coupon = getCookies('coupon');

    return (
        <>
            <Head>
                <meta
                    name="title"
                    content={
                        pageConfig.metaTitle
                            ? pageConfig.metaTitle
                            : pageConfig.title
                                ? pageConfig.title
                                : storeConfig.default_title
                                    ? storeConfig.default_title
                                    : 'Swift Pwa'
                    }
                />
                <meta
                    name="keywords"
                    content={
                        pageConfig.keywords
                            ? pageConfig.keywords
                            : pageConfig.title
                                ? pageConfig.title
                                : storeConfig.default_title
                                    ? storeConfig.default_title
                                    : 'Swift Pwa'
                    }
                />
                <meta name="description" content={ogData['og:description']} />
                <meta name="robots" content="INDEX,FOLLOW" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="format-detection" content="telephone=no" />
                {Object.keys(ogData).map((key, idx) => {
                    if (typeof ogData[key] === 'object' && ogData[key].type && ogData[key].type === 'meta') {
                        return <meta name={`${key}`} content={ogData[key].value} key={idx} />;
                    }
                    return <meta property={`${key}`} content={ogData[key]} key={idx} />;
                })}
                <title>{pageConfig.title ? pageConfig.title : storeConfig.default_title ? storeConfig.default_title : 'Swift Pwa'}</title>
                {schemaOrg
                    ? schemaOrg.map((val, idx) => (
                        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(val) }} key={idx} />
                    ))
                    : null}

                <link rel="canonical" href={pageConfig?.canonical ? pageConfig.canonical : canonicalUrl} />
                <link rel="alternate" hrefLang={defaultLang} href={canonicalUrl} />
            </Head>
            {features.customInstallApp.enabled && isMobileApp() !== 'app' ? <PopupInstallAppMobile /> : null}
            <header
                ref={refHeader}
                style={{
                    ...(stickyHeader && { position: 'sticky', top: 0, zIndex: 10 }),
                }}
            >
                {headerDesktop ? (
                    <div className="hidden-mobile">
                        <HeaderDesktop storeConfig={storeConfig} isLogin={isLogin} t={t} coupon={coupon} show={show} setShow={setShow} />
                    </div>
                ) : null}
                <div className="hidden-desktop">
                    {React.isValidElement(CustomHeader) ? (
                        <>
                            {React.cloneElement(CustomHeader, {
                                pageConfig,
                                storeConfig,
                                ...headerProps,
                            })}
                        </>
                    ) : (
                        <HeaderMobile {...headerProps} pageConfig={pageConfig} />
                    )}
                </div>
            </header>

            <main
                style={{ ...styles }}
                className={classNames('main-app', footerStyles.contentWrap, fullWidth && 'fullWidth', headerDesktop && footerStyles.marginTopMain)}
                id="maincontent"
            >
                <Loading open={state.backdropLoader} />
                <Message
                    open={state.toastMessage.open}
                    variant={state.toastMessage.variant}
                    setOpen={handleCloseMessage}
                    message={state.toastMessage.text}
                />
                {desktop && isLogin && coupon && coupon.length && show && headerDesktop ? <div style={{ height: 34 }} /> : null}
                {children}
            </main>
            <footer className={footerStyles.footerContainer} ref={refFooter}>
                <div className="hidden-mobile">{footer ? <Footer storeConfig={storeConfig} /> : null}</div>
                <div className="hidden-desktop">
                    <BottomNavigation active={pageConfig.bottomNav} />
                </div>
            </footer>
            <style jsx global>
                {`
                    .MuiSnackbar-root {
                        z-index: 10000 !important;
                    }
                    #ymDivCircle {
                        display: ${desktop ? 'block' : 'none'} !important;
                    }
                    #ym-notification {
                        display: none;
                    }
                `}
            </style>
            {
                storeConfig?.enabled_clickstream === '1' ? (
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{
                            __html: `
                                (function (window, document, url) {
                                    _swan = window._swan || {};
                                    const d = document;
                                    const g = d.createElement('script');
                                    const s = d.getElementsByTagName('script')[0];
                                    g.type = 'text/javascript';
                                    g.async = true;
                                    g.src = url;
                                    s.parentNode.insertBefore(g, s);
                                    g.onload = function () {
                                        _swan.init({
                                            app_id: 'mitra10_44335',
                                        }); 
                                    };
                                })(window, document, 'https://swan-web-sdk-prod.azureedge.net/trackingjs');
                            `,
                        }}
                    />
                ) : null
            }
        </>
    );
};

export default Layout;
